var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "one-col-box-grid-content-box",
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("account.hl_email")))]),
      _c("base-loading-input-wrapper", {
        class: { error: _vm.$v.emailInput.$error },
        attrs: {
          id: "input-change-email",
          type: "text",
          disabled: _vm.loading,
          loading: _vm.loading,
        },
        on: {
          blur: _vm.touchIfChangedOrReset,
          keyup: _vm.touchIfChangedOrReset,
        },
        model: {
          value: _vm.emailInput,
          callback: function ($$v) {
            _vm.emailInput = $$v
          },
          expression: "emailInput",
        },
      }),
      _vm.$v.emailInput.$error
        ? [
            !_vm.$v.emailInput.required
              ? _c("p", { staticClass: "error-message" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("account.form_field_required")) + " "
                  ),
                ])
              : _vm._e(),
            !_vm.$v.emailInput.email
              ? _c("p", { staticClass: "error-message" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("account.sl_invalid_email_error")) + " "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "base-button",
        {
          staticClass: "primary",
          class: { disabled: _vm.isButtonDisabled },
          attrs: {
            id: "btn-change-email",
            disabled: _vm.isButtonDisabled,
            "data-track-event": "navigate",
            "data-track-name": "change-email",
          },
          on: { click: _vm.changeEmail },
        },
        [_vm._v(" " + _vm._s(_vm.$t("account.btn_save_changes")) + " ")]
      ),
      _vm.showSuccessMessage
        ? _c("p", { staticClass: "success-message" }, [
            _vm._v(" " + _vm._s(_vm.$t("account.sl_email_saved")) + " "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }