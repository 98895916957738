<template>
  <base-header-bar-arrow-menu
    id="account-menu"
    data-track-event="navigate"
    data-track-name="account-menu"
    data-body-class-on-open="narrow-menu-open"
    to="/portal/contact"
    hover-disabled
  >
    <template #main-link>
      <span class="circle blue css-tooltip" :tooltip-text="customerEmail">{{
        customerFullNameOrEmailFirstLetter
      }}</span>
      <span>{{ customerFullNameOrEmail }}</span>
    </template>
    <template #menu-items>
      <base-header-bar-link
        data-track-event="navigate"
        data-track-name="account-details"
        to="/portal/contact"
      >
        <span>{{ $t('header.lnk_account_settings') }}</span>
      </base-header-bar-link>
      <base-header-bar-link
        data-track-event="navigate"
        data-track-name="logout"
        to="/logout"
      >
        <span>{{ $t('header.lnk_log_out') }}</span>
      </base-header-bar-link>
    </template>
  </base-header-bar-arrow-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseHeaderBarArrowMenu from './BaseHeaderBarArrowMenu.vue'
import BaseHeaderBarLink from './BaseHeaderBarLink.vue'
export default {
  components: {
    BaseHeaderBarArrowMenu,
    BaseHeaderBarLink
  },
  computed: {
    ...mapState({
      customerEmail: (state) => state.customer.email
    }),
    ...mapGetters({
      fullName: 'customer/fullName'
    }),
    customerFullNameOrEmail() {
      let fullName = this.fullName

      if (!fullName) {
        return this.customerEmail
      }

      return fullName
    },
    customerFullNameOrEmailFirstLetter() {
      return this.customerFullNameOrEmail.substr(0, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .css-tooltip {
    position: relative;
  }

  .css-tooltip:hover::after {
    content: attr(tooltip-text);
    position: absolute;
    background: #f5f5f5;
    border-radius: 3px;
    white-space: nowrap;
    bottom: 35px;
    line-height: 16px;
    color: #000;
    padding: 6px 10px;
    right: -100%;
    border: 1px solid #c0c0c0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }

  .css-tooltip:hover::before {
    bottom: 31px;
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    z-index: 1;
    border: 1px solid #c0c0c0;
    border-style: solid;
    transform: rotate(45deg);
    border-top: none;
    border-left: none;
    background: #f5f5f5;
    right: 7px;
  }

  ::v-deep .main-link {
    padding-left: 16px;
    min-width: 150px;

    span:nth-child(2) {
      max-width: 285px;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 5px;
      line-height: 20px;
      vertical-align: text-top;
    }

    &:hover::before {
      left: 0;
    }

    span.circle.blue {
      display: inline-block;
      border-radius: 14px;
      padding: 0 8px;
      background-color: #ccd6eb;
      line-height: 26px;
      color: #fff;
      margin-right: 5px;
      min-width: 26px;
      text-align: center;
    }
  }

  ::v-deep .menu-items {
    a + a {
      margin-left: 0;
    }
    span {
      font-size: 15px;
    }
  }
}
</style>
